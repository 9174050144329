import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import './testimonialsJumbotron.scss'


const TestimonialsJumbotron = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "testimonials/female_friends_enjoying_summer.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="jumbotron testimonialsJumbotron"
          fluid={imageData}
          style={{ borderRadius: '0' }}
        >
          <Img
            className="hidden"
            fluid={imageData}
            alt="Two female friends standing arm in arm while having a good time together in the city in summer."
          />
          <Container>
            <div className="testimonialsJumbotron-inside">
              <div className="testimonialsJumbotronContent">
                <h1 className="display-1 testimonialsJumbotronHeading">Testimonials</h1>
                {/* <Button variant="primary">Schedule now</Button> */}
              </div>
            </div>
          </Container>
        </BackgroundImage>
      )
    }}
  />
)

export default TestimonialsJumbotron
