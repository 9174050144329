import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import TestimonialsList from  '../components/page-sections/testimonials/testimonials-list'
import TestimonialsJumbotron from '../components/page-sections/testimonials/testimonialsJumbotron'
import ScheduleConsultation from '../components/page-sections/schedule-consultation'


const TestimonialsPage = (props) => (
  <Layout location={props.location}>
    <SEO title="G Jones for Hormones" />
    <TestimonialsJumbotron />
    <TestimonialsList />
    <ScheduleConsultation />
  </Layout>
)

export default TestimonialsPage
