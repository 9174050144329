import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const TestimonialsList = () => (
  <StaticQuery
    query={TESTIMONIALS_LIST_QUERY}
    render={({allMarkdownRemark}) => (
      <Container>
        {
          allMarkdownRemark.edges.map((edge, index) => {
            return(
              <React.Fragment key={index}>
                {
                  index % 2 === 0
                  ?
                  <Row style={{ marginTop: '3rem' }}>
                    <Col xs={12} md={8} lg={8}>
                      <blockquote className="testimonial">
                        <div dangerouslySetInnerHTML={{ __html: edge.node.html }} />
                        {
                          edge.node.frontmatter.name
                          ?
                          <footer className="testimonial-footer blockquote-footer">{edge.node.frontmatter.name}</footer>
                          :
                          ''
                        }
                      </blockquote>
                      <hr style={{ marginBottom: '2rem', marginTop: '2rem' }} />
                    </Col>
                    <Col />
                  </Row>
                  :
                  <Row>
                    <Col />
                    <Col xs={12} md={8} lg={8}>
                      <blockquote className="testimonial">
                        <div dangerouslySetInnerHTML={{ __html: edge.node.html }} />
                        {
                          edge.node.frontmatter.name
                          ?
                          <footer className="testimonial-footer blockquote-footer">{edge.node.frontmatter.name}</footer>
                          :
                          ''
                        }
                      </blockquote>
                      <hr style={{ marginBottom: '2rem', marginTop: '2rem' }} />
                    </Col>
                  </Row>
                }
              </React.Fragment>
            )
          })
        }
      </Container>
    )}
  />
)

export default TestimonialsList


const TESTIMONIALS_LIST_QUERY = graphql`
  query TestimonialsList {
    allMarkdownRemark (
      filter: {frontmatter: { page: {eq: "testimonial"}}}
      sort: {order: ASC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          frontmatter {
            date
            name
            page
          }
          html
        }
      }
    }
  }
`
